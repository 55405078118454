.my-photo {
    width: 300px;
    height: 300px;
    object-fit: cover;
    border: 5px solid grey;
}

.smooth-round {
    border-radius: 5%;
}

.round {
    border-radius: 50%;
}